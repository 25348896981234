import React from 'react';
import styled from 'styled-components';
import { ImgInsta1 } from '../../Imgs/imgInsta1.js';
import { ImgInsta2 } from '../../Imgs/imgInsta2.js';
import { ImgInsta3 } from '../../Imgs/imgInsta3.js';

export function Instagram() {

    return (
        <>
            <div id = "publicacoes"></div>
            <Content className = "flex container session change" id = "atuacao">
                <div>
                    <h1>O Instagram</h1>
                    <div className = "line"></div>
                </div>
                <h2>
                    Conheça a nossa página do instagram
                </h2>
                <a target="_blank" href = "https://www.instagram.com/brandaoesilva.advocacia/" className = "notVisible change botao">
                    <BotaoLinha>
                        <h2>@brandaoesilva.advocacia</h2>
                    </BotaoLinha>
                </a>
            </Content>
            <Publs className = "notVisible change intagramPublsResponsivo container flex">
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta1/>
                    <div>
                        <h2>
                        Aposentadoria Rural e todos os seus direitos
                        </h2>
                        <br/>
                        <p>
                        Os trabalhadores rurais têm direito à aposentadoria por idade, redução de 5 
                        anos. Portanto, os homens se aposentam aos 60 anos e as mulheres aos 55. 
                        Além da idade, o segurado deve comprovar que existe carência de 180 meses nas 
                        atividades rurais. As pessoas geralmente pensam que apenas segurados especiais 
                        têm direito a reduzir esse tempo. Os demais segurados (empregados, contribuintes 
                        individuais e trabalhadores rurais autônomos) também têm direito à redução da 
                        idade mínima, desde que comprovem que todo o tempo de contribuição é dedicado 
                        aos trabalhadores rurais.
                        </p>
                        <br/>
                        <p>
                        A reforma da previdência não alterou esse benefício aos trabalhadores rurais!! &#129488;
                        </p>
                        <br/>
                        <p>
                        Nos siga para mais dica como essa!
                        </p>
                    </div>
                    <button className = "change botao">
                        <BotaoLinha>
                            <p>Continuar lendo</p>
                        </BotaoLinha>
                    </button>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta2/>
                    <div>
                        <h2>
                        Já negaram o seu benefício? Saiba o que fazer!
                        </h2>
                        <br/>
                        <p>
                        Inicialmente procure um advogado!
                        </p>
                        <br/>
                        <p>
                        A sua primeira opção é o recurso administrativo:
                        </p>
                        <p>
                        - Apresentação dos motivos pelos os quais os benefícios devem ser concedido;
                        </p>
                        <p>
                        - Em sua maioria, a decisão da negativa é mantida, levando a um futuro processo 
                        judicial.
                        </p>
                        <br/>
                        <p>
                        No entanto também temos a Ação Judicial:
                        </p>
                        <p>
                        - Haverá perícia por médico especialista;
                        </p>
                        <p>
                        - Poderá receber os valores retroativos;
                        </p>
                        <p>
                        - Mais chance de aceitação do benefício, ou seja, o juiz conceder o benefício.
                        </p>
                        <br/>
                        <p>
                        Saiba qual a melhor forma de resolver o seu problema com todos os seus direitos 
                        nessa área conte conosco sempre.
                        </p>
                    </div>
                    <button className = "change botao">
                        <BotaoLinha>
                            <p>Continuar lendo</p>
                        </BotaoLinha>
                    </button>
                </Publ>
                <Publ className = "intagramPublResponsivo flex">
                    <ImgInsta3/>
                    <div>
                        <h2>
                        Quanto tempo dura a minha licença-maternidade?
                        </h2>
                        <p>
                        A licença-maternidade dura 120 dias, cerca de quatro meses, podendo ser 
                        iniciada entre o 28º dia anterior ao parto até o dia do nascimento do bebê.
                        </p>
                        <br/>
                        <p>
                        Entretanto, o recebimento do salário-maternidade começa mediante apresentação 
                        de um atestado médico ou certidão de nascimento do bebê.
                        </p>
                        <br/>
                        <p>
                        Saiba qual a melhor forma de resolver o seu problema com todos os seus 
                        direitos nessa área!
                        </p>
                    </div>
                    <button className = "change botao">
                        <BotaoLinha>
                            <p>Continuar lendo</p>
                        </BotaoLinha>
                    </button>
                </Publ>
            </Publs>
        </>
    )
};

const Content = styled.section`
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`;

const BotaoLinha = styled.div`
    border: 2px solid #DFAF60;
    padding: 8px 80px;
    p{
        font-weight: bold;
    }
`;

const Publs = styled.div`
    margin-top: 50px;
    justify-content: space-between;
    align-items: flex-start;
`;

const Publ = styled.div`
    flex-direction: column;
    width: 30%;
    img{
        width: 100%;
    }
    h2{
        text-align: center;
    }
    text-align: justify;
    gap: 15px;
`;

