import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { Moldura } from '../../Imgs/moldura.js';

export function Escritorio() {

    const navigate = useNavigate();

    return (
        <>
            <div id = "o-escritorio">
            </div>
            <Content className = "escritorioContentResponsivo container session change flex">
                <ImagemMoldura className = "escritorioImgMoldura">
                    <Moldura/>
                    <Imagem className = "escritorioImagem backgroundImage"></Imagem>
                </ImagemMoldura>
                <TituloTextoBotao className = "flex">
                    <div>
                        <h1>O Escritório</h1>
                        <div className = "line"></div>
                    </div>
                    <div>
                        <p>
                            O escritório Brandão & Silva Advogados Associados 
                            busca uma atuação eficiente e soluções estratégicas, 
                            entendendo o complexo do mundo jurídico somado com a 
                            atuação de profissionais comprometidos com a ética e 
                            transparência no relacionamento com o cliente. 
                            Entendemos a importância em adotar uma visão 
                            estratégica em cada etapa do processo, desde um 
                            atendimento personalizado e eficiente, o que nos 
                            torna uma referência na resolução dos casos.
                        </p>
                        <br/>
                        <p>
                            Com anos de uma atuação consolidada, nossa equipe 
                            traz consigo uma vasta experiência em diversas áreas 
                            do direito, indo além da atuação prática, tendo como 
                            diferencial a atuação acadêmica dos advogados no 
                            magistério superior, ministrando aulas como 
                            professores do Curso de Direito, possuindo 
                            especializações e aprofundamento dos estudos 
                            no mestrado acadêmico, refletindo diretamente 
                            na qualidade do serviço jurídico prestado, 
                            garantindo aos clientes acesso às mais recentes 
                            atualizações legislativas e jurisprudenciais.
                        </p>
                    </div>
                    <Botao className = "flex">
                        <a target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "change botao">
                            <BotaoLinha>
                                <p className = "bold">Falar com Advogado</p>
                            </BotaoLinha>
                        </a>
                    </Botao>
                </TituloTextoBotao>
            </Content>
        </>
    )
};

const Content = styled.section`
    justify-content: space-between;
    gap: 100px;
`;

const ImagemMoldura = styled.div`
    img{
        margin-bottom: -497px;
    }
`;

const Imagem = styled.div`
    width: 390px;
    height: 470px;
    z-index: 1;
    position: relative;
    margin-left: 21px;
`;

const TituloTextoBotao = styled.div`
    flex-direction: column;
    justify-content: center;
    gap: 45px;
    p{
        text-align: justify;
    }
`;

const Botao = styled.div`
    justify-content: center;
`;

const BotaoLinha = styled.div`
    border: 2px solid #DFAF60;
    padding: 8px 80px;
`;

