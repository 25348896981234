import React from 'react';
import styled from 'styled-components';
import { Moldura } from '../../Imgs/moldura.js';

export function Equipe() {

    function saberMais(pessoa){
        const body = document.querySelector('body');
        const conteudo = document.querySelectorAll('.saiba-mais-conteudo');
        body.classList.add("noScroll");
        conteudo[pessoa].classList.remove("hidden");
    }

    function fecharSaberMais(pessoa){
        const body = document.querySelector('body');
        const conteudo = document.querySelectorAll('.saiba-mais-conteudo');
        body.classList.remove("noScroll");
        conteudo[pessoa].classList.add("hidden");
    }

    return (
        <>
            <div id = "equipe"></div>
            <div className = "session">
                <div onClick = {() => fecharSaberMais(0)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa1"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Dr. João Lucas da Silva
                            </h1>
                            <br/>
                            <ul>
                                <li>
                                    <h2>
                                        Advogado inscrito na OAB/BA 53011;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Bacharel em Direito;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Ex-Procurador Geral Municipal (Biênio: 2021-2022);
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Mestrando em Ciências Humanas e Sociais 
                                        pela Universidade Federal do Oeste da 
                                        Bahia - UFOB;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Especialista em Direito Penal, Processo Penal 
                                        e Direito Público Aplicado;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Palestrante;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Autor do livro de obra jurídica: <i>"A aplicabilidade 
                                            do Princípio da Insignificância nos Crimes Contra 
                                            a Administração Pública"</i> pela Editora Dialética;
                                    </h2>
                                </li>
                            </ul>
                            <br/>
                            <a target="_blank" className = "lates" href = "http://lattes.cnpq.br/8878121562928959"><h2>
                                <u>Clique aqui para acessar o currículo Lattes</u>
                            </h2></a>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "change flex">
                                <p className = "bold">Falar com Advogado!</p>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(1)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa2"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Dr. Samuel Brandão Santiago Lessa
                            </h1>
                            <br/>
                            <ul>
                                <li>
                                    <h2>
                                        Advogado inscrito na OAB/BA 80687;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Professor do Magistério Superior;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Bacharel em Direito;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Graduado em Serviços Jurídicos, 
                                        Cartorários e Notariais;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Especialista em Direito Empresarial, 
                                        Previdenciário, Agrário, Penal e Processo Penal;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Possui formação complementar em Direito 
                                        Internacional, Teoria da Argumentação Jurídica 
                                        e de Liderança e Desenvolvimento de Equipes;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Formação Extensiva em Retórica e Oratória;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Palestrante;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Possui mais de 5 anos de experiência profissional no Banco do Nordeste do Brasil, militante da área rural e agrária;
                                    </h2>
                                </li>
                            </ul>
                            <br/>
                            <a target="_blank" className = "lates" href = "http://lattes.cnpq.br/0228565197964053"><h2>
                                <u>Clique aqui para acessar o currículo Lattes</u>
                            </h2></a>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "change flex">
                                <p className = "bold">Falar com Advogado!</p>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <div onClick = {() => fecharSaberMais(2)} className = "change flex saiba-mais-conteudo hidden">
                    <SaibaMais className = "flex fundoSaibaMais backgroundImage">
                        <div className = "imgMolduraResponsivo">
                            <ImgSaibaMais className = "backgroundImage2 pessoa3"></ImgSaibaMais>
                            <Moldura/>
                        </div>
                        <SaibaMaisTexto className = "equipeSaibamaisResponsivo flex">
                            <h1>
                                Geísa Lacerda Mota Novaes
                            </h1>
                            <br/>
                            <ul>
                                <li>
                                    <h2>
                                        Gerente Operacional e Administrativa (Controller Jurídico);
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Ex-Tabeliã Substituta do Cartório de Registro da Comarca de São Félix do Coribe;
                                    </h2>
                                </li>
                                <li>
                                    <h2>
                                        Possui mais de 10 anos de experiência em serviços cartorários e notariais;
                                    </h2>
                                </li>
                            </ul>
                            <br/>
                            <a target="_blank" className = "lates" href = "http://lattes.cnpq.br/1693960157723454"><h2>
                                <u>Clique aqui para acessar o currículo Lattes</u>
                            </h2></a>
                            <br/>
                            <br/>
                            <a target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "change flex">
                                <p className = "bold">Falar com Advogado!</p>
                            </a>
                        </SaibaMaisTexto>
                    </SaibaMais>
                </div>
                <Titulo className = "flex session change">
                    <div>
                        <h1>Equipe</h1>
                        <div className = "line"></div>
                    </div>
                </Titulo>
                <FundoMoldura className = "equipeFundoMolduraResonsivo flex container">
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa1"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Dr. João Lucas da Silva</h2>
                                <p>Sócio Administrador</p>
                            </div>
                            <button onClick = {() => saberMais(0)} className = "change botao2">
                                <BotaoLinha className = "botaoLinha">
                                    <p className = "bold">Saiba mais</p>
                                </BotaoLinha>
                            </button>
                        </TextoBotao>
                    </div>
                    <div className = "notVisible change">
                        <Fundo className = "backgroundImage2 pessoa2"></Fundo>
                        <Moldura></Moldura>
                        <TextoBotao className = "flex">
                            <div>
                                <h2>Dr. Samuel Brandão Santiago Lessa</h2>
                                <p>Sócio Administrador</p>
                            </div>
                            <button onClick = {() => saberMais(1)} className = "change botao2">
                                <BotaoLinha className = "botaoLinha">
                                    <p className = "bold">Saiba mais</p>
                                </BotaoLinha>
                            </button>
                        </TextoBotao>
                    </div>
                </FundoMoldura>
                <Content className = "equipeContentResponsivo flex container session notVisible change" id = "atuacao">
                    <Fundo style = {{marginLeft: "0px"}} className = "backgroundImage2 pessoa3"></Fundo>
                    <Moldura></Moldura>
                    <TextoBotao className = "flex">
                        <div>
                            <h2>Geísa Lacerda Mota Novaes</h2>
                            <p>Gerente Operacional</p>
                        </div>
                        <button onClick = {() => saberMais(2)} className = "change botao2">
                            <BotaoLinha className = "botaoLinha">
                                <p className = "bold">Saiba mais</p>
                            </BotaoLinha>
                        </button>
                    </TextoBotao>
                </Content>
            </div>
        </>
    )
};

const FundoMoldura = styled.div`
    img{
        width: 350px;
    }
    z-index: 1;
    position: relative;
    margin-top: 50px;
    justify-content: space-between;
    padding: 0px 50px;
    gap: 30px;
`;

const Fundo = styled.div`
    height: 300px;
    width: 300px;
    margin-bottom: -270px;
    margin-left: 25px;
    z-index: 2;
    position: relative;
`;

const TextoBotao = styled.div`
    background-color: #DFAF60;
    flex-direction: column;
    justify-content: space-between;
    margin-top: -5px;
    padding: 10px 10px 20px 10px;
    h2{
        font-weight: bold;
    }
    text-align: center;
    gap: 10px;
    width: 350px;
`;

const Content = styled.div`
    width: 100%;
    height: 950px;
    padding: 0px 0px 70px 0px;
    background-color: #0E1118;
    clip-path: polygon(0 12%, 100% 0%, 100% 100%, 0% 100%);
    background-color:black;
    h1{
        color: white;
    }
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -300px;
    img{
        width: 350px;
    }
`;

const Titulo = styled.div`
    flex-direction: column;
`;

const BotaoLinha = styled.div`
    border: 2px solid #DFAF60;
    padding: 8px 50px;
`;

const SaibaMais = styled.div`
    width: 960px;
    height: 750px;
    padding: 30px;
    align-items: center;
    justify-content: space-between;
    img{
        margin-top: -300px;
        width: 380px;
    }
    h1, h2{
        color: white
    }
    a:hover{
        background-color: #9A7240;
        color: white;
    }
    a{
        width: 300px;
        padding: 15px 6px;
        background-color: #DFAF60;
        gap: 10px;
        justify-content: center;
    }
    gap: 60px;
`;

const ImgSaibaMais = styled.div`
    height: 300px;
    width: 300px;
    z-index: 2;
    position: relative;
    margin-left: 40px;
`;

const SaibaMaisTexto = styled.div`
    flex-direction: column;
    text-align: center;
    li{
        list-style-type: disc;
    }
    ul{
        text-align: left;
    }
    li::marker {
        color: white;
      }
`;



