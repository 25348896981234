import React from 'react';

export function Moldura(){

    return (

        <>
            <img loading = "lazy" className = "noChange" src = "img/moldura.png"/>
        </>
    );
}