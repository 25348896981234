import React from 'react';

export function ImgInsta3(){

    return (

        <>
            <img loading = "lazy" className = "noChange" src = "img/direitoFamiliar.png"/>
        </>
    );
}