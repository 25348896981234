import React from 'react';
import styled from 'styled-components';
import { HashLink } from "react-router-hash-link"
import { Logo } from '../../Imgs/Logo.js';

export function Header() {

    const fixedNavbar = () => {
        let navbar = document.querySelector(".navbar");
        let navbarButton = document.querySelector(".navbarButton");
        let navbarResponsive = document.querySelector(".responsiveSandwich");
        if(window.innerWidth <= 871){
            navbar.classList.remove(".noResponsivo");
        }
        else{
            navbar.classList.add(".noResponsivo");
        }
        if(navbar.classList.contains(".noResponsivo")){
            if(window.scrollY >= 400){
                navbar.classList.add("navbarFixed");
                navbarButton.classList.add("navbarButtonFixed");
            }
            else{
                navbar.classList.remove("navbarFixed");
                navbarButton.classList.remove("navbarButtonFixed");
            }
        }
        else{
            if(window.scrollY >= 10){
                navbar.classList.add("navbarFixed");
                navbarButton.classList.add("navbarButtonFixed");
                navbarResponsive.classList.remove("responsiveSandwichMargin");
            }
            else{
                navbar.classList.remove("navbarFixed");
                navbarButton.classList.remove("navbarButtonFixed");
                navbarResponsive.classList.add("responsiveSandwichMargin");
            }
        }
    }
    window.addEventListener('scroll', fixedNavbar);

    function responsiveNavigate(){
        document.querySelector(".sandwichMenu").classList.toggle("hidden");
        document.querySelector("body").classList.toggle("noOverflow");
        document.querySelector(".menuS").classList.toggle("change");
    }

    function clickSandwich() {
        document.querySelector(".menuS").classList.toggle("change");
        document.querySelector(".sandwichMenu").classList.toggle("hidden");
        document.querySelector("body").classList.toggle("noOverflow");
    }

    return (
        <>
            <FundoLogo className = "fundoLogo backgroundImage flex" id = "inicio">
                <Logo/>
            </FundoLogo>
            <Content className = "changeNavbar navbar noResponsivo">
                <NavBar className = "changeNavbar navbarButton">
                    <NavBarBotao target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "navbarBotao change flex">
                        <i className = "fa fa-phone" aria-hidden="true"></i>
                        <p>(77) 99819-6064</p>
                    </NavBarBotao>
                    <NavBarTexto className = "navbarTextoResponsivo">
                        <ul>
                            <li><HashLink to = "/#inicio" className = "buttonNav" smooth><p>Início</p></HashLink></li>
                            <li><HashLink to = "/#o-escritorio" className = "buttonNav" smooth><p>O Escritório</p></HashLink></li>
                            <li><HashLink to = "/#areas-atuacao" className = "buttonNav" smooth><p>Áreas de Atuação</p></HashLink></li>
                            <li><HashLink to = "/#equipe" className = "buttonNav" smooth><p>Equipe</p></HashLink></li>
                            <li><HashLink to = "/#publicacoes" className = "buttonNav" smooth><p>Publicações</p></HashLink></li>
                            <li><HashLink to = "/#contato" className = "buttonNav" smooth><p>Contato</p></HashLink></li>
                        </ul>      
                    </NavBarTexto>              
                </NavBar>
                <div className = "responsiveSandwich responsiveSandwichMargin container">
                    <button className = "menuS" onClick = {clickSandwich}>
                        <div className = "menu bar1"></div>
                        <div className = "menu bar2"></div>
                        <div className = "menu bar3"></div>
                    </button>
                    <Logo/>
                    <SandwichMenu className = "sandwichMenu hidden">
                        <HashLink to = "/#inicio" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Início</p></HashLink>
                        <HashLink to = "/#o-escritorio" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>O Escritório</p></HashLink>
                        <HashLink to = "/#areas-atuacao" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Áreas de atuação</p></HashLink>
                        <HashLink to = "/#equipe" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Equipe</p></HashLink>
                        <HashLink to = "/#publicacoes" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Publicações</p></HashLink>
                        <HashLink to = "/#contato" className = "buttonNav" onClick = {responsiveNavigate} smooth><p>Contato</p></HashLink>
                    </SandwichMenu>
                </div>
            </Content>
        </>
    );
}

const FundoLogo = styled.section`
    width: 100%;
    padding: 40px;
    img{
        width: 500px;
    }
    justify-content: center;
`;

const Content = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: -51px;
    position: relative;
    z-index: 2;
    p{
        font-weight: bold;
    }
`;

const NavBar = styled.div`
    background-color: #F5F5F5;
    width: 90%;
    display: flex;
    align-items: center;
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        width: 100%;
    }
`;

const NavBarBotao = styled.a`
    width: 250px;
    padding: 15px 6px;
    background-color: #DFAF60;
    gap: 10px;
    justify-content: center;
`;

const NavBarTexto = styled.section`
    width: 100%;
    padding: 0px 100px;
    p:hover{
        color: #9A7240;
        transition: 0.2s ease-in;
    }
`;

const SandwichMenu = styled.div`
    z-index: 1;
    background-color: white;
    position: fixed;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px 10px;
    transition: all 0.3s ease-in-out 0.1s;
`;