import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/Header/Header.js';
import { Footer } from '../../components/Footer/Footer.js';
import { Escritorio } from './Escritorio.js';
import { Principios } from './Principios.js';
import { Atuacao } from './Atuacao.js';
import { Equipe } from './Equipe.js';
import { Instagram } from './Instagram.js';
import { Contato } from './Contato.js';

export default function HomePage() {

    const activeScroll = () => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
    }
    window.addEventListener('scroll', activeScroll);

    useEffect(() => {
        document.querySelectorAll('.change').forEach((div, index) => {
            if(!div.classList.contains("noChange")){
                if(div.getBoundingClientRect().top < window.innerHeight) {
                    div.classList.remove("notVisible");
                }
            }
        })
      }, [])

    return (
        <>
            <Header/>
            <Escritorio/>
            <Principios/>
            <Atuacao/>
            <Equipe/>
            <Instagram/>
            <Contato/>
            <Wpp target="_blank" href = "https://wa.me/5577998196064?text=Ol%C3%A1!%20Gostaria%20de%20uma%20consulta%20com%20um%20advogado." className = "wppBotao flex">
                <i className = "fa fa-whatsapp fa-3x" aria-hidden="true"></i>
            </Wpp>
            <Footer/>
        </>
    )
};

const Wpp = styled.a`
    background-color: #00AA01;
    width: 90px;
    height: 90px;
    border-radius: 100px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0px 10px 10px 0px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.55);
    i{
        color: white;
    }
    z-index: 2;
`;