import React from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";

export function Contato() {

    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => {console.log(data)};

    return (
        <>
            <div id = "contato"></div>
            <Content className = "container session change" id = "atuacao">
                <Fundo className = "flex">
                    <div>
                        <h1>Contato</h1>
                        <div className = "line2"></div>
                    </div>
                    <TextForm className = "notVisible change contatoTextFormResponsivo flex">
                        <div>
                            <h2>
                                Possui alguma dúvida? 
                            </h2>
                            <h2>
                                Entre em contato com a gente
                            </h2>
                            <IconeTexto className = "contatoIconeTextoResponsivo flex">
                                <i className = "fa fa-whatsapp fa-2x" aria-hidden="true"></i>
                                <div>
                                    <p>
                                        Whatsapp
                                    </p>
                                    <p>
                                        +55 (77) 99819-6064
                                    </p>
                                </div>
                            </IconeTexto>
                            <IconeTexto className = "contatoIconeTextoResponsivo flex">
                                <i className = "fa fa-envelope-o fa-2x" aria-hidden="true"></i>
                                <div>
                                    <p>
                                        Email
                                    </p>
                                    <p>
                                        brandaoesilva.contato@gmail.com
                                    </p>
                                </div>
                            </IconeTexto>
                            <IconeTexto className = "contatoIconeTextoResponsivo flex">
                            <i className = "fa fa-map-marker fa-2x" aria-hidden="true"></i>
                                <div>
                                    <p>
                                        Endereço
                                    </p>
                                    <p>
                                        Praça Dos Afonsos, n° 23, Andar 01, Sala 01, Centro 
                                    </p>
                                    <p>
                                        Santa Maria da Vitória - BA
                                    </p>
                                </div>
                            </IconeTexto>
                            <RedesSociais className ="contatoRedesSociaisResponsivo flex">
                                <button>
                                    <i className = "fa fa-facebook fa-lg" aria-hidden="true"></i>
                                </button>
                                <a target="_blank" href = "https://www.instagram.com/brandaoesilva.advocacia/">
                                    <i className = "fa fa-instagram fa-lg" aria-hidden="true"></i>
                                </a>
                            </RedesSociais>
                        </div>
                        <Form className = "contatoFormResponsivo">
                            <FormBorda action="https://formsubmit.co/brandaoesilva.contato@gmail.com" method="POST" className = "flex">
                                <h2>
                                    Tire suas dúvidas
                                </h2>
                                <div>
                                    <input {...register("nome")} type = "text" placeholder = "Nome" required/>
                                    <input {...register("email")} type = "email" placeholder = "Email" required/>
                                    <input {...register("contato")} type = "text" placeholder = "Contato" required/>
                                    <input {...register("assunto")} type = "text" placeholder = "Assunto" required/>
                                    <textarea {...register("mensagem")} placeholder = "Mensagem"/>
                                </div>

                                <input type="hidden" name="accessKey" value="6790dd98-88f3-461a-94c7-e6e432b8643e">

                                </input>
                                <Botao type = "submit" onClick = {() => handleSubmit(onSubmit)()} className = "change flex">
                                    <p className = "bold">Enviar</p>
                                </Botao>
                            </FormBorda>
                        </Form>
                    </TextForm>
                </Fundo>
            </Content>
        </>
    )
};

const Content = styled.section`
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    z-index: 1;
    position: relative;
`;

const Fundo = styled.div`
    background-color: #DFAF60;
    justify-content: center;
    padding: 50px 50px;
    flex-direction: column;
`;

const TextForm = styled.div`
    background-color: #DFAF60;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    gap: 10px;
`;

const Form = styled.div`
    padding: 5px;
    background-color: #0E1118;
    h2{
        color: white;
    }
    width: 55%;
    text-align: center;
`;

const FormBorda = styled.form`
    border: 2px solid #DFAF60;
    padding: 15px 30px;
    input, textarea{
        width: 100%;
        margin-top: 5px;
        height: 35px;
        padding: 5px;
    }
    textarea{
        height: 100px;
        resize: none;
        font-family: 'arial', sans-serif;
    }
    button:hover{
        background-color: #9A7240;
        color: white;
    }
    flex-direction: column;
    gap: 15px;
`;

const Botao = styled.button`
    width: 200px;
    padding: 15px 6px;
    background-color: #DFAF60;
    gap: 10px;
    justify-content: center;
`;

const IconeTexto = styled.div`
    padding: 15px 6px;
    background-color: #DFAF60;
    gap: 10px;
    align-items: center;
    i{
        width: 50px;
    }
`;

const RedesSociais = styled.div`
    gap: 15px;
    margin-top: 15px;
`;