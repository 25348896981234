import React from 'react';
import styled from 'styled-components';

export function Atuacao() {

    return (
        <>
            <div id = "areas-atuacao">
            </div>
            <Content className = "flex container session change">
                <Titulo>
                    <h1>Áreas de Atuação</h1>
                    <div className = "line"></div>
                </Titulo>
                <Cards className = "change notVisible flex">
                    <Card>
                        <Borda>
                            <p>
                                Assessoria Jurídica Empresarial
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Planejamento Patrimonial e Sucessório (Holding)
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Advocacia Criminal Especializada
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Advocacia Cível
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Advocacia Previdenciária
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Regularização Fundiária 
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Direito Agrário
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Direito Bancário
                            </p>
                        </Borda>
                    </Card>
                    <Card>
                        <Borda>
                            <p>
                                Direito do Consumidor
                            </p>
                        </Borda>
                    </Card>
                </Cards>
            </Content>
        </>
    )
};

const Content = styled.section`
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px solid #0E1118;
    padding: 30px;
    margin-top: 100px;
`;

const Titulo = styled.div`
    margin-top: -45px;
    background-color: #F5F5F5;
    padding: 0px 30px;
`;

const Cards = styled.div`
    padding: 50px 0px 40px 0px;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
`;

const Card = styled.div`
    background-color: #0E1118;
    padding: 5px;
    p{
        color: white;
        font-weight: bold;
    }
`;

const Borda = styled.div`
    border: 2px solid #0E1118;
    padding: 10px 0px;
    width: 340px;
    text-align: center;
`;