import React from 'react';
import styled from 'styled-components';

export function Principios() {

    return (
        <>
            <Content className = "principiosFundoResponsivo session" id = "principios">
                <TituloCards className = "flex container">
                    <div>
                        <h1>Nossos Princípios</h1>
                        <div className = "line"></div>
                    </div>
                </TituloCards>
            </Content>
            <Cards className = "principiosCardsResponsivo container flex notVisible change">
                <Card className = "card1 backgroundImage">
                    <h2>
                        Missão
                    </h2>
                    <br/>
                    <p>
                        Proporcionar aos clientes uma solução estratégica 
                        para os assuntos jurídicos pautados na ética, 
                        transparência e expertise. Buscamos não apenas 
                        resolver casos, mas antecipar desafios e oferecer 
                        soluções estratégicas inovadoras através de um 
                        estudo sistematizado da situação.
                    </p>
                </Card>
                <Card className = "card2 backgroundImage">
                    <h2>
                        Visão
                    </h2>
                    <br/>
                    <p>
                        Ofertar uma advocacia que se destaca 
                        pela excelência na prestação de serviços 
                        jurídicos, oferecendo soluções inovadoras 
                        e estratégicas, contribuindo para o avanço 
                        do conhecimento jurídico.
                    </p>
                </Card>
                <Card className = "card3 backgroundImage">
                    <h2>
                        Valores
                    </h2>
                    <br/>
                    <p>
                        Nossos valores estão pautados na 
                        espiritualidade como um pilar 
                        fundamental na vida prática e 
                        profissional, ensejando um discernimento 
                        espiritual concedido por Deus na 
                        resolução dos casos. Do pilar espiritual 
                        sobressaem todos os demais valores: 
                        ética, transparência, honestidade, 
                        integridade.
                    </p>
                </Card>
            </Cards>
        </>
    )
};

const Content = styled.section`
    width: 100%;
    height: 420px;
    background-color: #0E1118;
    clip-path: polygon(0% 100%, 0% 0%, 100% 20%, 100% 100%);
    background-color:black;
    h1{
        color: white;
    }
    margin-top: 100px;
`;

const TituloCards = styled.div`
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Cards = styled.div`
    gap: 50px;
    margin-top: -230px;
`;

const Card = styled.div`
    h2, p{
        color: white;
    }
    padding: 50px;
    z-index: 1;
    text-align: center;
`;

